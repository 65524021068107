<template>
  <div class="columns login-set">
    <div class="column left-side-login is-flex is-justify-content-center is-flex-direction-column is-align-items-center">
      <b-notification
        type="is-danger"
        has-icon
        class="notification"
        aria-close-label="Close notification"
        role="alert"
        v-if="Forbidden"
      >
        Неверный логин или пароль, пожалуйста введите корректные данные!
      </b-notification>
      <div class="login-center">
        <div class="has-text-centered">
          <img
            src="../assets/logo.png"
            width="140"
            alt=""
          >
        </div>
        <p>
          <b>Административная панель!</b>
        </p>
        <p class="my-2">Рады видеть Вас в нашем сервисе!</p>


        <form @submit.prevent="LoginVerification">
          <div class="control">
            <span><small>E-mail</small></span>
            <input
              class="input"
              type="text"
              v-model="login"
              placeholder="Введите email"
            >
          </div>
          <div class="control mt-1">
            <span><small>Пароль</small></span>
            <input
              class="input"
              type="password"
              v-model="password"
              placeholder="Введите пароль"
            >
          </div>
          <button class="button is-info my-3">Войти</button>
        </form>
      </div>
    </div>
    <div class="column right-side-login"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: null,
      password: null,
      Forbidden: false,
    };
  },
  methods: {
    async LoginVerification() {
      await this.$axios.post(`users/signin`, {
        email: this.login,
        password: this.password,
      }).then(response => {
        if(response?.data?.token && response.data?.user?.role_id === 1) {
          localStorage.setItem("token", response.data.token);
          this.$axios.defaults.headers.common["token"] = response.data.token;
          this.$router.push("/");
        } else {
          this.Forbidden = true;
          setTimeout(() => this.Forbidden = false, 3000);
        }
      }).catch(() => {
        this.Forbidden = true;
        setTimeout(() => this.Forbidden = false, 3000);
      });
    },

  },
};
</script>

<style>
.login-set {
  min-height: 101vh;
}

.notification {
  width: 400px;
}

.left-side-login {
  background-color: #dddce1;
}

.right-side-login {
  background: url('../assets/bg.jpg') no-repeat center center;
  background-size: cover;
}

@media (max-width: 768px) {
  .right-side-login {
    display: none;
  }

  .login-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
</style>
